.ClientLayout {
  background-color: #f6f8fb;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100%;
}

.ClientLayout button {
  /*overrides*/
  border-style: none;
  background: transparent;
  /* color: inherit; */
}

.ClientPortalHeader {
  height: 71px;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: baseline;

  margin-bottom: 10px;

  border-bottom: solid 1px rgb(80, 89, 94);
}

.ClientHomeLink {
  width: 100px;
  height: 64px;
  text-decoration: none;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.ClientHomeLink img {
  width: 58px;
  height: 33px;
}

.ClientHomeLink-title {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: rgb(235, 238, 239);
  text-transform: uppercase;

  margin-top: 12px;
}

.ClientPortalMain {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.WeatherOpticsLink {
  text-decoration: none;
  margin-right: 36px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ClientHomeLink {
  border-right: solid 1px rgba(61, 87, 170, 0.15);
  justify-content: center;
}

.MenuToggleButton {
  display: none;
}

.ClientPortalDatetime {
  margin-left: 30px;
  margin-right: 20px;

  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: rgb(235, 238, 239);
}

.ClientPortalNav {
  width: 80px;

  display: flex;
  flex-direction: column;

  background-color: rgb(44, 52, 59);
  border-right: solid 1px rgba(0, 0, 0, 0.3);
}

.ClientPortalSubmenu {
  height: auto;
  background-color: rgb(44, 52, 59);
  position: absolute;
  left: 80px;

  display: flex;
  flex-direction: row;

  z-index: 1006;
}

.ClientPortalSubmenu .ClientPortalNavLink {
  width: 80px;
  box-sizing: border-box;
}

.ClientPortalContent {
  flex: 1 1 auto;
  overflow-y: auto;
}

.ClientPortalNav {
  display: flex;
  flex-direction: column;
  align-items: fill;
  justify-content: space-between;
}

.ClientPortalNav-ScrollArea {
  flex: 1 1 auto;
  overflow-y: auto !important;
  padding-bottom: 96px;

  --mask: linear-gradient(to bottom,
      rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 0) 100% 50% / 100% 100% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

ClientPortalNav-ScrollArea::webkit-scrollbar {
  display: none;
}

.ClientPortalNav-BottomArea {}

.ClientPortalNavLink {
  padding: 10px 20px 10px 20px;
  color: #a2a6a9;
  text-decoration: none;

  font-size: 0.5rem;
  letter-spacing: 1px;
  text-transform: uppercase;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.ClientPortalNavLink>div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* @media screen and (max-height: 780px) {
  .ClientPortalNavLink {
    height: 33px;
  }
} */

.ClientPortalNavLink.active {
  background-color: rgba(3, 160, 253, 0.1);
  /* color: #1482d9; */
  color: white;
  /* border-left: 5px solid #1482d9; */
  /* padding-left: 15px; */
}

.ClientPortalNavLink.inactive:hover {
  background-color: rgba(1, 128, 220, 0.05);
}

.ClientPortalNavLink .icon {
  font-size: 32px;
  display: block;
  margin: auto;
  margin-bottom: 5px;
}

.ClientPortalAuxLink {
  padding: 7px 25px 7px 25px;
  color: #a2a6a9;
}

.ClientPortalAuxLink.active {
  background-color: rgba(3, 160, 253, 0.1);
  color: #1482d9;
  border-left: 5px solid #1482d9;
  padding-left: 20px;
}

.ClientPortalAuxLink.inactive:hover {
  background-color: rgba(1, 128, 220, 0.05);
}

.ClientPortalAuxLink .icon {
  font-size: 30px;
}

.TrailStartDialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.TrailStartDialogContent .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.TrailStartDialogContent .title {
  font-size: 35px;
  text-align: center;
  margin-bottom: 20px;
}

.TrailStartDialogContent .subtitle {
  font-size: 18px;
  color: #e1e1e1;
  width: 75%;
  text-align: center;
  margin-bottom: 20px;
}

.TrailStartDialogContent .tab-highlights {
  color: #b7b7b7;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.TrailStartDialogContent .tab-highlight {
  flex-grow: 1;
  flex-basis: 0;
  margin: 10px;
  font-size: 16px;
}

.TrailStartDialogContent .tab-highlight img {
  height: 70px;
  margin-bottom: 15px;
}

.TrailStartDialogContent .icon {
  font-size: 70px;
  width: 100%;
  color: #1482d9;
  text-align: center;
  margin-bottom: 20px;
}

.TrailStartDialogContent .demo {
  font-size: 16px;
  color: #b7b7b7;
  text-align: center;
}

.TrailStartDialogContent a {
  color: #1482d9;
  text-decoration: none;
}

.TrailStartDialogContent .trial-length {
  font-size: 12px;
  font-weight: 600;
  color: #b0b0b0;
  text-align: center;
  margin-bottom: 10px;
}

.TrailStartDialogContent .footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.TrailStartDialogContent .close-button {
  font-size: 20px;
  padding-top: 15px;
  color: #1482d9;
}

@media only screen and (min-width: 960px) {
  .mobile-error {
    display: none;
  }
}

@media only screen and (max-width: 960px) {
  .mobile-error {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1010;
  }

  .mobile-error-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .mobile-error-card {
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.6);
    width: 200px;
    padding: 20px;
    margin: auto auto;
    margin-top: 50%;
    text-align: center;
  }
}

@media only print {
  .ClientPortalHeader {
    display: none;
  }

  .ClientPortalNav {
    display: none;
  }

  .ClientLayout {
    height: auto;
    position: static;
  }
}