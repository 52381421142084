.NowcastAssetGraphsContainer {
  position: absolute;
  bottom: 24px;
  left: 14px;
  right: 64px;
  background-image: none;
  display: flex;
  max-height: 50%;
  pointer-events: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.NowcastAssetGraphsContainer>* {
  pointer-events: auto;
}

.NowcastAssetGraphsContainer .selected-asset-info {
  min-width: 300px;
  height: 315px;
  border-radius: 11px 11px 0px;
}

.NowcastAssetGraphsContainer .RoutesTableComponent {
  margin-top: 15px;
  width: 100%;
  height: 100%;
}

.NowcastAssetGraphsContainer .AssetGraphComponent {
  margin-top: 15px;
  width: 100%;
  height: 100%;
}

.NowcastAssetGraphsContainer .ClientWidgetContainer {
  margin: 0px !important;
  box-shadow: none;
  height: 240px;
}

.NowcastAssetGraphsContainer .ClientWidgetContainer-header {
  height: 18px !important;
  box-shadow: none;
  flex-wrap: wrap;
  background: transparent;
}

.NowcastAssetGraphsContainer .ClientWidgetContainer-title {
  font-size: 16px;
  font-weight: 600;
  margin: 12px 0 0 25px;
  width: 100%;
}

.NowcastAssetGraphsContainer .ClientWidgetContainer-body {
  height: 220px !important;
  border-radius: 0 0 11px 0;
  padding: 0 11px 0 0;
}

.NowcastAssetGraphsContainer .graph-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2px 18px;
}

.AboveShipmentTableContainer {
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
  width: 100%;
  pointer-events: none;
}

.AboveShipmentTableContainer>* {
  pointer-events: auto;
}

.css-13rep3f-MuiPaper-root {
  box-shadow: none;
}

.NowcastMenu-Container {
  width: auto;
  position: absolute;
  top: 65px;
  right: 10px;
  z-index: 1005;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  pointer-events: none;
}

.NowcastMenu-Container.graph-expanded {
  height: 40%;
}

.NowcastMenu-Submenu {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  max-height: 100%;
  margin-left: 12px;

  pointer-events: auto;
}

.NowcastMenu-Submenu .content {
  overflow-y: auto;
}

.NowcastLayerDescriptionComponent-Container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  bottom: 20px;
  left: 14px;
  right: 77px;
  height: 220px;
  z-index: 1004;
  box-sizing: border-box;
}

.NowcastLayerDescriptionComponent {
  width: 100%;
  max-height: 200px;
}

.nowcast-layer-description-text {
  width: 100%;
  overflow: auto;
}

.MapCalloutView .event-name {
  padding: 10px 12px 5px;

  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  color: rgb(235, 238, 239);
}

.MapCalloutView .event-description {
  padding: 0px 12px 10px;

  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  color: rgb(235, 238, 239);
}